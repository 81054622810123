<template>
  <div class="index">
    <ul>
      <li>
        <p>Software Auditing and Review</p>
      </li>
      <li>
        <p>DevOps Architecture and Design</p>
      </li>
      <li>
        <p>IT Staff Empowerment and Alignment</p>
      </li>
    </ul>
  </div>
  <div>
    <p>
      <a href="mailto:ecommerce@harmonizedsoftware.com">Contact Us</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: disc;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
